import Keycloak from 'keycloak-js';

export namespace Security {
    const keycloak = new Keycloak({
        url: 'https://auth.yesikov.dp.ua/',
        realm: 'yesikov',
        clientId: 'family-tree-web-client'
    });

    export function init(onAuthenticatedCallback: () => void) {
        keycloak.init({
            onLoad: 'login-required'
        }).then(function (authenticated) {
            if (authenticated) {
                onAuthenticatedCallback()
            } else {
                console.warn('Keycloak authorization attempt failed');
                keycloak.login({
                    redirectUri: "https://auth.yesikov.dp.ua",
                }).then(_ => console.info('Redirected to the login page'));
            }
        }).catch(function (f) {
            console.error('Keycloak authorization failed', f);
        });
    }

    export function getToken(): Promise<string | undefined> {
        try {
            return keycloak.updateToken(30).then(() => {
                return keycloak.token
            })
        } catch (error) {
            console.error('Failed to refresh and get token:', error);
            return Promise.resolve(undefined)
        }
    }

    export function logout(): Promise<void> {
        return keycloak.logout()
    }

    export function isAdmin(): boolean {
        return keycloak.hasResourceRole("admin")
    }
}